function _newArrowCheck(innerThis, boundThis) { if (innerThis !== boundThis) { throw new TypeError("Cannot instantiate an arrow function"); } }

import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.parse-float.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.assign.js";
import "core-js/modules/es.array.index-of.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.error.to-string.js";
import { TutorialDiagnostics } from './tutorial-diagnostics.mjs';
import { TutorialCompleter } from './tutorial-autocompletion.mjs';
$(document).ready(function () {
  var tutorial = new Tutorial();

  if (typeof TutorialCompleter !== 'undefined') {
    tutorial.$completer = new TutorialCompleter(tutorial);
  }

  if (typeof TutorialDiagnostics !== 'undefined') {
    tutorial.$diagnostics = new TutorialDiagnostics(tutorial);
  }

  window.tutorial = tutorial;
});
var TUTORIAL_IS_SERVER_AVAILABLE = false;

function Tutorial() {
  var thiz = this;
  this.$initTimingLog();
  this.isBS3 = !window.bootstrap;

  if (this.isBS3) {
    document.body.classList.add('tutorial-is-bs3');
  }

  this.onInit = function (handler) {
    this.$initCallbacks.add(handler);
  };

  this.onProgress = function (handler) {
    this.$progressCallbacks.add(handler);
  };

  this.startOver = function () {
    thiz.$removeState(function () {
      thiz.$serverRequest('remove_state', null, function () {
        window.location.replace(window.location.origin + window.location.pathname);
      });
    });
  };

  this.skipSection = function (sectionId) {
    if (TUTORIAL_IS_SERVER_AVAILABLE) {
      thiz.$serverRequest('section_skipped', {
        sectionId: sectionId
      }, null);
    }
  };

  this.scrollIntoView = function (element) {
    element = $(element);
    var rect = element[0].getBoundingClientRect();

    if (rect.top < 0 || rect.bottom > $(window).height()) {
      if (element[0].scrollIntoView) {
        element[0].scrollIntoView(false);
        document.body.scrollTop += 20;
      }
    }
  };

  thiz.$initializeVideos();
  thiz.$initializeExercises();
  thiz.$initializeServer();
}

Tutorial.prototype.$initTimingLog = function () {
  try {
    if (performance.mark !== undefined) {
      performance.mark('tutorial-start-mark');
    }
  } catch (e) {
    console.log('Error initializing log timing: ' + e.message);
  }
};

Tutorial.prototype.$logTiming = function (name) {
  try {
    if (performance.mark !== undefined && performance.measure !== undefined && performance.getEntriesByName !== undefined && this.queryVar('log-timings') === '1') {
      performance.mark(name + '-mark');
      performance.measure(name, 'tutorial-start-mark', name + '-mark');
      var entries = performance.getEntriesByName(name);
      console.log('(Timing) ' + name + ': ' + Math.round(entries[0].duration) + 'ms');
    }
  } catch (e) {
    console.log('Error logging timing: ' + e.message);
  }
};

Tutorial.prototype.queryVar = function (name) {
  return decodeURI(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURI(name).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'));
};

Tutorial.prototype.$idSelector = function (id) {
  return '#' + id.replace(/(:|\.|\[|\]|,|=|@)/g, '\\$1');
};

Tutorial.triggerMathJax = function () {
  if (window.MathJax) {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }
};

Tutorial.prototype.$initCallbacks = $.Callbacks();

Tutorial.prototype.$fireInit = function () {
  var thiz = this;

  try {
    thiz.$initCallbacks.fire();
  } catch (e) {
    console.log(e);
  }
};

Tutorial.prototype.$progressCallbacks = $.Callbacks();
Tutorial.prototype.$progressEvents = [];

Tutorial.prototype.$hasCompletedProgressEvent = function (element) {
  var thiz = this;

  for (var e = 0; e < thiz.$progressEvents.length; e++) {
    var event = thiz.$progressEvents[e];

    if ($(event.element).is($(element))) {
      if (event.completed) {
        return true;
      }
    }
  }

  return false;
};

Tutorial.prototype.$fireProgress = function (event) {
  this.$progressEvents.push(event);

  try {
    this.$progressCallbacks.fire(event);
  } catch (e) {
    console.log(e);
  }
};

Tutorial.prototype.$fireSectionCompleted = function (element) {
  var thiz = this;

  function fireCompleted(el) {
    var event = {
      element: el,
      event: 'section_completed'
    };
    thiz.$fireProgress(event);
  }

  var section = $(element).parent().closest('.section');

  if (section.length === 0) {
    return;
  }

  var components = section.find('.tutorial-exercise, .tutorial-question, .tutorial-video');
  var allCompleted = true;

  for (var c = 0; c < components.length; c++) {
    var component = components.get(c);

    if (!thiz.$hasCompletedProgressEvent(component)) {
      allCompleted = false;
      break;
    }
  }

  if (allCompleted) {
    fireCompleted($(section).get(0));
    var previousSections = section.prevAll('.section');
    previousSections.each(function () {
      var components = $(this).find('.tutorial-exercise, .tutorial-question');

      if (components.length === 0) {
        fireCompleted(this);
      }
    });
    var parentSection = section.parent().closest('.section');

    if (parentSection.length > 0) {
      this.$fireSectionCompleted(section);
    }
  }
};

Tutorial.prototype.$removeConflictingProgressEvents = function (progressEvent) {
  var thiz = this;
  var event;

  for (var i = thiz.$progressEvents.length - 1; i >= 0; i--) {
    event = thiz.$progressEvents[i];

    if (event.event === 'question_submission') {
      if (event.data.label === progressEvent.data.label & progressEvent.data.label !== undefined) {
        thiz.$progressEvents.splice(i, 1);
        return;
      }
    }
  }
};

Tutorial.prototype.$fireProgressEvent = function (event, data) {
  var thiz = this;
  var progressEvent = {
    event: event,
    data: data
  };

  if (event === 'exercise_submission' || event === 'question_submission') {
    var element = $('.tutorial-exercise[data-label="' + data.label + '"]').add('.tutorial-question[data-label="' + data.label + '"]');

    if (element.length > 0) {
      progressEvent.element = element;

      if (event === 'exercise_submission') {
        progressEvent.completed = typeof data.completed !== 'undefined' ? data.completed : true;
      } else {
        progressEvent.completed = data.answer !== null;
      }
    }
  } else if (event === 'section_skipped') {
    var exerciseElement = $(thiz.$idSelector(data.sectionId));
    progressEvent.element = exerciseElement;
    progressEvent.completed = false;
  } else if (event === 'video_progress') {
    var videoElement = $('iframe[src="' + data.video_url + '"]');

    if (videoElement.length > 0) {
      progressEvent.element = videoElement;
      progressEvent.completed = 2 * data.time > data.total_time;
    }
  }

  this.$removeConflictingProgressEvents(progressEvent);

  if (progressEvent.element) {
    this.$fireProgress(progressEvent);
    thiz.$fireSectionCompleted(progressEvent.element);
  }
};

Tutorial.prototype.$initializeProgress = function (progressEvents) {
  var thiz = this;

  for (var i = 0; i < progressEvents.length; i++) {
    var progress = progressEvents[i];
    var progressEvent = progress.event;
    var progressEventData = {};

    if (progressEvent === 'exercise_submission') {
      progressEventData.label = progress.data.label;
      progressEventData.correct = progress.data.correct;
    } else if (progressEvent === 'question_submission') {
      progressEventData.label = progress.data.label;
      progressEventData.answer = progress.data.answer;
    } else if (progressEvent === 'section_skipped') {
      progressEventData.sectionId = progress.data.sectionId;
    } else if (progressEvent === 'video_progress') {
      progressEventData.video_url = progress.data.video_url;
      progressEventData.time = progress.data.time;
      progressEventData.total_time = progress.data.total_time;
    }

    thiz.$fireProgressEvent(progressEvent, progressEventData);
  }

  Shiny.addCustomMessageHandler('tutorial.progress_event', function (progress) {
    thiz.$fireProgressEvent(progress.event, progress.data);
  });
};

Tutorial.prototype.$serverRequest = function (type, data, success, error) {
  var _Shiny$shinyapp$confi = Shiny.shinyapp.config,
      sessionId = _Shiny$shinyapp$confi.sessionId,
      workerId = _Shiny$shinyapp$confi.workerId;
  return $.ajax({
    type: 'POST',
    url: "session/".concat(sessionId, "/dataobj/").concat(type, "?w=").concat(workerId),
    contentType: 'application/json',
    data: JSON.stringify(data),
    dataType: 'json',
    success: success,
    error: error
  });
};

Tutorial.prototype.$recordEvent = function (label, event, data) {
  var params = {
    label: label,
    event: event,
    data: data
  };
  this.$serverRequest('record_event', params, null);
};

Tutorial.prototype.$countLines = function (str) {
  return str.split(/\r\n|\r|\n/).length;
};

Tutorial.prototype.$injectScript = function (src, onload) {
  var script = document.createElement('script');
  script.src = src;
  var firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
  $(script).on('load', onload);
};

Tutorial.prototype.$debounce = function (func, wait, immediate) {
  var timeout;
  return function () {
    var context = this;
    var args = arguments;

    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

Tutorial.prototype.$initializeVideos = function () {
  var youtubeRegex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  var vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;

  function isYouTubeVideo(src) {
    return src.match(youtubeRegex);
  }

  function isVimeoVideo(src) {
    return src.match(vimeoRegex);
  }

  function isVideo(src) {
    return isYouTubeVideo(src) || isVimeoVideo(src);
  }

  function normalizeVideoSrc(src) {
    var youtubeMatch = src.match(youtubeRegex);

    if (youtubeMatch) {
      return "https://www.youtube.com/embed/".concat(youtubeMatch[2], "?enablejsapi=1");
    }

    var vimeoMatch = src.match(vimeoRegex);

    if (vimeoMatch) {
      return "https://player.vimeo.com/video/".concat(vimeoMatch[1]);
    }

    return src;
  }

  function setContainerSize(container, width, height) {
    var aspectRatio = 9 / 16;

    if (!width) {
      width = '100%';
    }

    if (width.slice(-1) === '%') {
      container.css('width', width);

      if (!height) {
        height = 0;
        var paddingBottom = parseFloat(width) * aspectRatio + '%';
        container.css('padding-bottom', paddingBottom);
      }

      container.css('height', height);
    } else {
      if ($.isNumeric(width)) {
        width = width + 'px';
      }

      container.css('width', width);

      if (!height) {
        height = parseFloat(width) * aspectRatio + 'px';
      }

      container.css('height', height);
    }
  }

  $('img').each(function () {
    var videoSrc = $(this).attr('src');

    if (!isVideo(videoSrc)) {
      return;
    }

    $(this).css('display', 'none');
    var width = $(this).get(0).style.width;
    var height = $(this).get(0).style.height;
    $(this).css('width', '').css('height', '');
    var attrs = {};
    $.each(this.attributes, function (idex, attr) {
      switch (attr.nodeName) {
        case 'width':
          {
            width = String(attr.nodeValue);
            break;
          }

        case 'height':
          {
            height = String(attr.nodeValue);
            break;
          }

        case 'src':
          {
            attrs.src = normalizeVideoSrc(attr.nodeValue);
            break;
          }

        default:
          {
            attrs[attr.nodeName] = attr.nodeValue;
          }
      }
    });
    $(this).replaceWith(function () {
      var iframe = $('<iframe/>', attrs);
      iframe.addClass('tutorial-video');

      if (isYouTubeVideo(videoSrc)) {
        iframe.addClass('tutorial-video-youtube');
      } else if (isVimeoVideo(videoSrc)) {
        iframe.addClass('tutorial-video-vimeo');
      }

      iframe.attr('allowfullscreen', '');
      iframe.css('display', '');
      var container = $('<div class="tutorial-video-container"></div>');
      setContainerSize(container, width, height);
      container.append(iframe);
      return container;
    });
  });
  this.$logTiming('initialized-videos');
};

Tutorial.prototype.$initializeVideoPlayers = function (videoProgress) {
  if (/\bQt\//.test(window.navigator.userAgent)) {
    return;
  }

  this.$initializeYouTubePlayers(videoProgress);
  this.$initializeVimeoPlayers(videoProgress);
};

Tutorial.prototype.$videoPlayerRestoreTime = function (src, videoProgress) {
  for (var v = 0; v < videoProgress.length; v++) {
    var id = videoProgress[v].id;

    if (src === id) {
      var time = videoProgress[v].data.time;
      var totalTime = videoProgress[v].data.total_time;

      if (time > 10 && totalTime - time > 10) {
        return time;
      }
    }
  }

  return 0;
};

Tutorial.prototype.$initializeYouTubePlayers = function (videoProgress) {
  var thiz = this;
  var videos = $('iframe.tutorial-video-youtube');

  if (videos.length > 0) {
    this.$injectScript('https://www.youtube.com/iframe_api', function () {
      YT.ready(function () {
        videos.each(function () {
          var video = $(this);
          var videoUrl = video.attr('src');
          var player = null;
          var lastState = -1;

          function reportProgress() {
            thiz.$reportVideoProgress(videoUrl, player.getCurrentTime(), player.getDuration());
          }

          function restoreTime() {
            var restoreTime = thiz.$videoPlayerRestoreTime(videoUrl, videoProgress);

            if (restoreTime > 0) {
              player.mute();
              player.playVideo();
              setTimeout(function () {
                player.pauseVideo();
                player.seekTo(restoreTime, true);
                player.unMute();
              }, 2000);
            }
          }

          function onReady() {
            restoreTime();
          }

          function onStateChange() {
            var state = player.getPlayerState();
            var isNotStarted = state === -1;
            var isCued = state === YT.PlayerState.CUED;
            var isPlaying = state === YT.PlayerState.PLAYING;
            var isDuplicate = state === lastState;

            if (!(isNotStarted || isCued) && (isPlaying || isDuplicate)) {
              reportProgress();
            }

            lastState = state;
          }

          player = new YT.Player(this, {
            events: {
              onReady: onReady,
              onStateChange: onStateChange
            }
          });
          window.setInterval(onStateChange, 5000);
        });
      });
    });
  }
};

Tutorial.prototype.$initializeVimeoPlayers = function (videoProgress) {
  var thiz = this;
  var videos = $('iframe.tutorial-video-vimeo');

  if (videos.length > 0) {
    this.$injectScript('https://player.vimeo.com/api/player.js', function () {
      videos.each(function () {
        var video = $(this);
        var videoUrl = video.attr('src');
        var player = new Vimeo.Player(this);
        var lastReportedTime = null;
        player.ready().then(function () {
          var restoreTime = thiz.$videoPlayerRestoreTime(videoUrl, videoProgress);

          if (restoreTime > 0) {
            player.getVolume().then(function (volume) {
              player.setCurrentTime(restoreTime).then(function () {
                player.pause().then(function () {
                  player.setVolume(volume);
                });
              });
            });
          }
        });

        function reportProgress(data, throttle) {
          if (throttle === undefined) {
            throttle = false;
          }

          if (throttle && lastReportedTime != null && data.seconds - lastReportedTime < 5) {
            return;
          }

          thiz.$reportVideoProgress(videoUrl, data.seconds, data.duration);
          lastReportedTime = data.seconds;
        }

        player.on('play', reportProgress);
        player.on('pause', reportProgress);
        player.on('ended', reportProgress);
        player.on('timeupdate', function (data) {
          reportProgress(data, true);
        });
      });
    });
  }
};

Tutorial.prototype.$reportVideoProgress = function (videoUrl, time, totalTime) {
  this.$serverRequest('video_progress', {
    video_url: videoUrl,
    time: time,
    total_time: totalTime
  });
};

Tutorial.prototype.$initializeExercises = function () {
  this.$initializeExerciseEditors();
  this.$initializeExerciseSolutions();
  this.$initializeExerciseEvaluation();
  this.$logTiming('initialized-exercises');
};

Tutorial.prototype.$exerciseForLabel = function (label) {
  return $('.tutorial-exercise[data-label="' + label + '"]');
};

Tutorial.prototype.$forEachExercise = function (operation) {
  return $('.tutorial-exercise').each(function () {
    var exercise = $(this);
    operation(exercise);
  });
};

Tutorial.prototype.$exerciseSupportCode = function (label) {
  var selector = '.tutorial-exercise-support[data-label="' + label + '"]';
  var code = $(selector).children('pre').children('code');

  if (code.length > 0) {
    return code.text();
  } else {
    return null;
  }
};

Tutorial.prototype.$exerciseSolutionCode = function (label) {
  return this.$exerciseSupportCode(label + '-solution');
};

Tutorial.prototype.$exerciseHintDiv = function (label) {
  var id = 'section-' + label + '-hint';
  var hintDiv = $('div#' + id);

  if (hintDiv.length > 0 && !hintDiv.hasClass('section')) {
    return hintDiv;
  } else {
    return null;
  }
};

Tutorial.prototype.$exerciseHintsCode = function (label) {
  var hint = this.$exerciseSupportCode(label + '-hint');

  if (hint !== null) {
    return [hint];
  }

  var hints = [];
  var index = 1;

  while (true) {
    var hintLabel = label + '-hint-' + index++;
    hint = this.$exerciseSupportCode(hintLabel);

    if (hint !== null) {
      hints.push(hint);
    } else {
      break;
    }
  }

  if (hints.length > 0) {
    return hints;
  } else {
    return null;
  }
};

Tutorial.prototype.$exerciseContainer = function (el) {
  return $(el).closest('.tutorial-exercise');
};

Tutorial.prototype.$showExerciseProgress = function (label, button, show) {
  var exercise = this.$exerciseForLabel(label);
  var outputFrame = exercise.children('.tutorial-exercise-output-frame');
  var runButtons = exercise.find('.btn-tutorial-run');

  if (button === 'run') {
    button = exercise.find('.btn-tutorial-run').last();
  }

  var spinner = 'fa-spinner fa-spin fa-fw';

  if (show) {
    outputFrame.addClass('recalculating');
    runButtons.addClass('disabled');

    if (button !== null) {
      var runIcon = button.children('i');
      runIcon.removeClass(button.attr('data-icon'));
      runIcon.addClass(spinner);
    }
  } else {
    outputFrame.removeClass('recalculating');
    runButtons.removeClass('disabled');
    runButtons.each(function () {
      var button = $(this);
      var runIcon = button.children('i');
      runIcon.addClass(button.attr('data-icon'));
      runIcon.removeClass(spinner);
    });
  }
};

Tutorial.prototype.kMinLines = 3;

Tutorial.prototype.$attachAceEditor = function (target, code, options) {
  var engineModes = {
    js: 'javascript'
  };
  var optsDefaults = {
    engine: 'r'
  };
  options = Object.assign({}, optsDefaults, options);
  options.engine = engineModes[options.engine] || options.engine;
  var editor = ace.edit(target);
  editor.setHighlightActiveLine(false);
  editor.setShowPrintMargin(false);
  editor.setShowFoldWidgets(false);
  editor.setBehavioursEnabled(true);
  editor.renderer.setDisplayIndentGuides(false);
  editor.setTheme('ace/theme/textmate');
  editor.$blockScrolling = Infinity;
  editor.session.setMode("ace/mode/".concat(options.engine));
  editor.session.getSelection().clearSelection();
  editor.session.setNewLineMode('unix');
  editor.session.setTabSize(2);
  editor.setValue(code, -1);
  editor.setOptions({
    enableBasicAutocompletion: true
  });
  return editor;
};

Tutorial.prototype.$exerciseEditor = function (label) {
  return this.$exerciseForLabel(label).find('.tutorial-exercise-code-editor');
};

Tutorial.prototype.$initializeExerciseEditors = function () {
  var thiz = this;
  this.$forEachExercise(function (exercise) {
    var optsScript = exercise.children('script[data-ui-opts="1"]').detach();
    var optsChunk = optsScript.length === 1 ? JSON.parse(optsScript.text()) : {};
    var label = exercise.attr('data-label');
    var caption = optsChunk.caption;

    function createId(suffix) {
      return 'tutorial-exercise-' + label + '-' + suffix;
    }

    exercise.on('focusin', function () {
      $('.btn-tutorial-solution').each(function () {
        if (exercise.has($(this)).length === 0) {
          thiz.$removeSolution(thiz.$exerciseContainer($(this)));
        }
      });
    });
    var code = '';
    var codeBlocks = exercise.children('pre.text, pre.lang-text');
    codeBlocks.each(function () {
      var codeElement = $(this).children('code');

      if (codeElement.length > 0) {
        code = code + codeElement.text();
      } else {
        code = code + $(this).text();
      }
    });
    codeBlocks.remove();
    var lines = code.split(/\r\n|\r|\n/).length;

    for (var i = lines; i < thiz.kMinLines; i++) {
      code = code + '\n';
    }

    exercise.wrapInner('<div class="tutorial-exercise-output-frame"></div>');
    var outputFrame = exercise.children('.tutorial-exercise-output-frame');
    var inputDiv = $('<div class="tutorial-exercise-input panel panel-default"></div>');
    inputDiv.attr('id', createId('input'));
    var panelHeading = $('<div class="panel-heading tutorial-panel-heading"></div>');
    inputDiv.append(panelHeading);
    var panelHeadingLeft = $('<div class="tutorial-panel-heading-left"></div>');
    var panelHeadingRight = $('<div class="tutorial-panel-heading-right"></div>');
    panelHeading.append(panelHeadingLeft);
    panelHeading.append(panelHeadingRight);
    panelHeadingLeft.html(caption);
    var panelBody = $('<div class="panel-body"></div>');
    inputDiv.append(panelBody);

    function addSubmitButton(icon, style, text, check, datai18n) {
      var button = $('<button class="btn ' + style + ' btn-xs btn-tutorial-run"></button>');
      button.append($('<i class="fa ' + icon + '"></i>'));
      button.append(' ' + '<span data-i18n="button.' + datai18n + '">' + text + '</span>');
      var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
      var title = text;
      var kbdText = (isMac ? 'Cmd' : 'Ctrl') + '+Shift+Enter';

      if (!check) {
        title = title + ' (' + kbdText + ')';
        button.attr('data-i18n-opts', '{"kbd": "' + kbdText + '"}');
      }

      button.attr('title', title);
      button.attr('data-i18n', '');
      button.attr('data-i18n-attr-title', 'button.' + datai18n + 'title');

      if (check) {
        button.attr('data-check', '1');
      }

      button.attr('data-icon', icon);
      button.on('click', function () {
        thiz.$removeSolution(exercise);
        thiz.$showExerciseProgress(label, button, true);
      });
      panelHeadingRight.append(button);
      return button;
    }

    var runButton = addSubmitButton('fa-play', 'btn-success', 'Run Code', false, 'runcode');

    if (optsChunk.has_checker) {
      addSubmitButton('fa-check-square-o', 'btn-primary', 'Submit Answer', true, 'submitanswer');
    }

    var codeDiv = $('<div class="tutorial-exercise-code-editor"></div>');
    var codeDivId = createId('code-editor');
    codeDiv.attr('id', codeDivId);
    panelBody.append(codeDiv);
    panelBody.append(optsScript);
    exercise.prepend(inputDiv);
    var outputDiv = $('<div class="tutorial-exercise-output"></div>');
    outputDiv.attr('id', createId('output'));
    outputFrame.append(outputDiv);
    var editor = thiz.$attachAceEditor(codeDivId, code, optsChunk);
    var setupCode = null;
    var completion = exercise.attr('data-completion') === '1';
    var diagnostics = exercise.attr('data-diagnostics') === '1';
    var startoverCode = exercise.attr('data-startover') === '1' ? code : null;
    var engine = optsChunk.engine;

    if (engine.toLowerCase() !== 'r') {
      diagnostics = null;
    }

    editor.tutorial = {
      label: label,
      engine: engine,
      setup_code: setupCode,
      completion: completion,
      diagnostics: diagnostics,
      startover_code: startoverCode
    };

    function bindExecutionKey(name, key) {
      var macKey = key.replace('Ctrl+', 'Command+');
      editor.commands.addCommand({
        name: name,
        bindKey: {
          win: key,
          mac: macKey
        },
        exec: function exec(editor) {
          runButton.trigger('click');
        }
      });
    }

    bindExecutionKey('execute1', 'Ctrl+Enter');
    bindExecutionKey('execute2', 'Ctrl+Shift+Enter');

    function bindInsertKey(name, keys, text) {
      if (typeof keys === 'string') {
        keys = {
          win: keys,
          mac: keys.replace('Ctrl+', 'Command+')
        };
      }

      if (typeof text === 'string') {
        text = {
          r: text,
          fallback: text
        };
      }

      editor.commands.addCommand({
        name: name,
        bindKey: keys,
        exec: function exec(editor) {
          if (text[editor.tutorial.engine]) {
            editor.insert(text[editor.tutorial.engine]);
          } else if (text.fallback) {
            editor.insert(text.fallback);
          }
        }
      });
    }

    bindInsertKey('insertPipe', 'Ctrl+Shift+M', {
      r: ' %>% '
    });
    bindInsertKey('insertArrow', 'Alt+-', {
      r: ' <- ',
      fallback: ' = '
    });
    runButton.on('click', function () {
      editor.focus();
    });

    function toggleTabCommands(enable) {
      var tabCommandKeys = {
        indent: {
          win: 'Tab',
          mac: 'Tab'
        },
        outdent: {
          win: 'Shift+Tab',
          mac: 'Shift+Tab'
        }
      };
      ['indent', 'outdent'].forEach(function (name) {
        var command = editor.commands.byName[name];
        command.bindKey = enable ? tabCommandKeys[name] : null;
        editor.commands.addCommand(command);
      });
      $(editor.container).toggleClass('ace_indent_off', !enable);
    }

    editor.on('focus', function () {
      toggleTabCommands(true);
    });
    editor.commands.addCommand({
      name: 'escape',
      bindKey: {
        win: 'Esc',
        mac: 'Esc'
      },
      exec: function exec() {
        toggleTabCommands(false);
      }
    });

    var updateAceHeight = function updateAceHeight() {
      var lines = exercise.attr('data-lines');

      if (lines && lines > 0) {
        editor.setOptions({
          minLines: lines,
          maxLines: lines
        });
      } else {
        editor.setOptions({
          minLines: thiz.kMinLines,
          maxLines: Math.max(Math.min(editor.session.getLength(), 15), thiz.kMinLines)
        });
      }
    };

    updateAceHeight();
    editor.getSession().on('change', updateAceHeight);
    thiz.$addSolution(exercise, panelHeadingLeft, editor);
    exercise.parents('.section').on('shown', function () {
      editor.resize(true);
    });
  });
};

Tutorial.prototype.$initializeExerciseSolutions = function () {
  var thiz = this;
  $(document).on('mouseup', function (ev) {
    var exercise = thiz.$exerciseContainer(ev.target);

    if (exercise.length === 0) {
      thiz.$forEachExercise(thiz.$removeSolution);
    }
  });
};

Tutorial.prototype.$addSolution = function (exercise, panelHeading, editor) {
  var thiz = this;
  var label = exercise.attr('data-label');
  var solution = thiz.$exerciseSolutionCode(label);
  var hints = thiz.$exerciseHintsCode(label);

  if (hints !== null && solution !== null) {
    hints.push(solution);
    solution = null;
  }

  var hintDiv = thiz.$exerciseHintDiv(label);

  function addHelperButton(icon, caption, classBtn, datai18n) {
    var button = $('<button class="btn btn-light btn-xs btn-tutorial-solution"></button>');
    button.attr('title', caption);
    button.attr('data-i18n', '');
    button.addClass(classBtn);
    button.append($('<i class="fa ' + icon + '"></i>'));

    if (datai18n) {
      if (typeof datai18n === 'string') {
        datai18n = {
          key: datai18n
        };
      }

      button.attr('data-i18n-attr-title', datai18n.key + 'title');
      var buttonText = $("<span class=\"d-none d-sm-inline-block d-md-none d-lg-inline-block\">".concat(caption, "</span>"));
      buttonText.attr('data-i18n', datai18n.key);

      if (datai18n.opts) {
        buttonText.attr('data-i18n-opts', JSON.stringify(datai18n.opts));
      }

      button.append(document.createTextNode(' '));
      button.append(buttonText);

      if (datai18n.opts) {
        button.attr('data-i18n-opts', JSON.stringify(datai18n.opts));
      }
    } else {
      button.append(' ' + caption);
    }

    panelHeading.append(button);
    return button;
  }

  function addHintButton(caption, datai18n) {
    datai18n = datai18n || 'button.hint';
    return addHelperButton('fa-lightbulb-o', caption, 'btn-tutorial-hint', datai18n);
  }

  function recordHintRequest(index) {
    thiz.$recordEvent(label, 'exercise_hint', {
      type: solution !== null ? 'solution' : 'hint',
      index: index
    });
  }

  if (editor.tutorial.startover_code !== null) {
    var startOverButton = addHelperButton('fa-refresh', 'Start Over', 'btn-tutorial-start-over', 'button.startover');
    startOverButton.on('click', function () {
      editor.setValue(editor.tutorial.startover_code, -1);
      thiz.$clearExerciseOutput(exercise);
    });
  }

  if (hintDiv != null) {
    hintDiv.addClass('tutorial-hint');
    hintDiv.css('display', 'none');
    var button = addHintButton('Hint', {
      key: 'button.hint',
      count: 1
    });
    button.on('click', function () {
      recordHintRequest(0);
      var outputFrame = exercise.children('.tutorial-exercise-output-frame');

      if (outputFrame.find('.tutorial-hint').length === 0) {
        var panel = $("<div class=\"".concat(thiz.isBS3 ? 'panel panel-default' : 'card', " tutorial-hint-panel\"></div>"));
        var panelBody = $("<div class=\"".concat(thiz.isBS3 ? 'panel-body' : 'card-body', "\"></div>"));
        var hintDivClone = hintDiv.clone().attr('id', '').css('display', 'inherit');
        panelBody.append(hintDivClone);
        panel.append(panelBody);
        outputFrame.prepend(panel);
      } else {
        outputFrame.find('.tutorial-hint-panel').remove();
      }
    });
  } else if (solution || hints) {
    var isSolution = solution !== null;
    var editorLines = thiz.kMinLines;

    if (solution) {
      editorLines = Math.max(thiz.$countLines(solution), editorLines);
    } else {
      for (var i = 0; i < hints.length; i++) {
        editorLines = Math.max(thiz.$countLines(hints[i]), editorLines);
      }
    }

    var hintIndex = 0;

    var _button = addHintButton(isSolution ? 'Solution' : hints.length > 1 ? 'Hints' : 'Hint', isSolution ? {
      key: 'button.solution',
      count: 1
    } : {
      key: 'button.hint',
      opts: {
        count: hints.length
      }
    });

    _button.on('click', function (ev) {
      recordHintRequest(hintIndex);
      var solutionText = solution !== null ? solution : hints[hintIndex];
      var visible = _button.parent().find('div.popover:visible').length > 0;

      if (visible) {
        console.log('Removing hint popover', ev);
        thiz.$removeSolution(exercise);
        editor.focus();
        return;
      }

      console.log('Revealing hint popover', ev);

      var popover = _button.popover({
        placement: 'top',
        template: '<div class="popover tutorial-solution-popover" role="tooltip">' + '<div class="arrow"></div>' + '<div class="popover-title tutorial-panel-heading"></div>' + '<div class="popover-content"></div>' + '</div>',
        content: solutionText,
        container: _button.parent(),
        boundary: $('.topics').get(0),
        viewport: $('.topics').get(0)
      });

      var popoverIsInserted = false;
      popover.on('inserted.bs.popover', function (ev) {
        var _this = this;

        if (popoverIsInserted) return;
        console.log('Instantiating hint popover', ev);
        var popoverTip = thiz.isBS3 ? popover.data('bs.popover').tip() : $(window.bootstrap.Popover.getInstance(popover).tip);
        var content = popoverTip.find('.popover-content');
        var solutionEditor = thiz.$attachAceEditor(content.get(0), solutionText);
        solutionEditor.setReadOnly(true);
        solutionEditor.setOption('minLines', Math.min(editorLines, 10));
        solutionEditor.setOption('maxLines', 10);
        setTimeout(function () {
          _newArrowCheck(this, _this);

          content.parent().css('top', "-".concat(content.parent().height(), "px"));
        }.bind(this));
        var popoverTitle = popoverTip.find('.popover-title');

        if (solution === null && hints.length > 1) {
          var nextHintButton = $("<button class=\"btn btn-light ".concat(thiz.isBS3 ? 'btn-xs' : 'btn-sm', " btn-tutorial-next-hint\"></button>"));
          nextHintButton.append($('<span data-i18n="button.hintnext">Next Hint</span>'));
          nextHintButton.append(' ');
          nextHintButton.append($('<i class="fa fa-angle-double-right"></i>'));
          nextHintButton.on('click', function () {
            hintIndex = hintIndex + 1;
            solutionEditor.setValue(hints[hintIndex], -1);

            if (hintIndex === hints.length - 1) {
              nextHintButton.addClass('disabled');
              nextHintButton.prop('disabled', true);
            }

            recordHintRequest(hintIndex);
          });

          if (hintIndex === hints.length - 1) {
            nextHintButton.addClass('disabled');
            nextHintButton.prop('disabled', true);
          }

          popoverTitle.append(nextHintButton);
        }

        var copyButton = $("<button class=\"btn btn-info ".concat(thiz.isBS3 ? 'btn-xs' : 'btn-sm', " btn-tutorial-copy-solution pull-right\"></button>"));
        copyButton.append($('<i class="fa fa-copy"></i>'));
        copyButton.append(' ');
        copyButton.append($('<span data-i18n="button.copyclipboard">Copy to Clipboard</span>'));
        popoverTitle.append(copyButton);
        var clipboard = new ClipboardJS(copyButton[0], {
          text: function text(trigger) {
            return solutionEditor.getValue();
          }
        });
        clipboard.on('success', function (e) {
          thiz.$removeSolution(exercise);
          editor.focus();
        });
        copyButton.data('clipboard', clipboard);
        popoverTip.css('left', '0');
        var popoverArrow = popoverTip.find('.arrow');
        popoverArrow.css('left', _button.position().left + _button.outerWidth() / 2 + 'px');
        popoverTip.trigger('i18n');
        popoverIsInserted = true;
      });

      _button.on('shown.bs.popover', function () {
        var popoverElement = $('.tutorial-solution-popover');
        thiz.scrollIntoView(popoverElement);

        if (!thiz.isBS3) {
          window.bootstrap.Popover.getInstance(popover).update();
        }
      });

      _button.popover('show');

      editor.focus();
    });
  }
};

Tutorial.prototype.$removeSolution = function (exercise) {
  var solutionButton = exercise.find('.btn-tutorial-copy-solution');

  if (solutionButton.length > 0) {
    solutionButton.data('clipboard').destroy();
  }

  if (window.bootstrap) {
    var popover = exercise.find('.tutorial-solution-popover');
    if (!popover.length) return;
    window.bootstrap.Popover.getInstance(popover.get(0)).dispose();
  } else {
    exercise.find('.tutorial-solution-popover').popover('destroy');
  }
};

Tutorial.prototype.$initializeExerciseEvaluation = function () {
  var thiz = this;

  function exerciseLabel(el) {
    return thiz.$exerciseContainer(el).attr('data-label');
  }

  function ensureExerciseVisible(el) {
    var exerciseEl = thiz.$exerciseContainer(el)[0];
    thiz.scrollIntoView(exerciseEl);
  }

  var exerciseInputBinding = new Shiny.InputBinding();
  $.extend(exerciseInputBinding, {
    find: function find(scope) {
      return $(scope).find('.tutorial-exercise-code-editor');
    },
    getValue: function getValue(el) {
      if (!this.clicked && !this.restore) {
        return null;
      }

      var value = {};
      value.label = exerciseLabel(el);
      value.should_check = this.should_check;
      var editor = ace.edit($(el).attr('id'));
      value.code = value.should_check ? editor.getSession().getValue() : editor.getSelectedText() || editor.getSession().getValue();
      value.restore = this.restore;
      value.timestamp = new Date().getTime();
      return value;
    },
    setValue: function setValue(el, value) {
      var editor = ace.edit($(el).attr('id'));
      editor.getSession().setValue(value.code);
      this.runButtons(el).trigger('click');

      if (window.shinytest) {
        setTimeout(function () {
          editor.blur();
        }, 0);
      }
    },
    getType: function getType(el) {
      return 'learnr.exercise';
    },
    subscribe: function subscribe(el, callBack) {
      var binding = this;
      this.runButtons(el).on('click.exerciseInputBinding', function (ev) {
        binding.restore = false;
        binding.clicked = true;
        binding.should_check = ev.delegateTarget.hasAttribute('data-check');
        callBack(true);
      });
      $(el).on('restore.exerciseInputBinding', function (ev, options) {
        binding.restore = true;
        binding.clicked = false;
        binding.should_check = options.should_check;
        callBack(true);
      });
    },
    unsubscribe: function unsubscribe(el) {
      this.runButtons(el).off('.exerciseInputBinding');
    },
    runButtons: function runButtons(el) {
      var exercise = thiz.$exerciseContainer(el);
      return exercise.find('.btn-tutorial-run');
    },
    restore: false,
    clicked: false,
    check: false
  });
  Shiny.inputBindings.register(exerciseInputBinding, 'tutorial.exerciseInput');
  var exerciseOutputBinding = new Shiny.OutputBinding();
  $.extend(exerciseOutputBinding, {
    find: function find(scope) {
      return $(scope).find('.tutorial-exercise-output');
    },
    onValueError: function onValueError(el, err) {
      Shiny.unbindAll(el);
      this.renderError(el, err);
    },
    renderValue: function renderValue(el, data) {
      thiz.$showExerciseProgress(exerciseLabel(el), null, false);
      this.outputFrame(el).children().not($(el)).remove();
      Shiny.renderContent(el, data);

      if (window.bootstrapStylePandocTables) {
        window.bootstrapStylePandocTables();
      }

      if (window.PagedTableDoc) {
        window.PagedTableDoc.initAll();
      }

      var restoring = thiz.$exerciseContainer(el).data('restoring');

      if (!restoring) {
        ensureExerciseVisible(el);
        thiz.$exerciseContainer(el).data('restoring', false);
      } else {
        thiz.$logTiming('restored-exercise-' + exerciseLabel(el));
      }
    },
    showProgress: function showProgress(el, show) {
      if (show) {
        thiz.$showExerciseProgress(exerciseLabel(el), null, show);
      } else {}
    },
    outputFrame: function outputFrame(el) {
      return $(el).closest('.tutorial-exercise-output-frame');
    }
  });
  Shiny.outputBindings.register(exerciseOutputBinding, 'tutorial.exerciseOutput');
};

Tutorial.prototype.$clearExerciseOutput = function (exercise) {
  var outputFrame = $(exercise).find('.tutorial-exercise-output-frame');
  var outputDiv = $(outputFrame).children('.tutorial-exercise-output');
  outputFrame.children().not(outputDiv).remove();
  outputDiv.empty();
};

Tutorial.prototype.$initializeStorage = function (identifiers, success) {
  var thiz = this;

  if (!(typeof window.Promise !== 'undefined' && typeof window.indexedDB !== 'undefined')) {
    success({});
    return;
  }

  var dbName = 'LearnrTutorialProgress';
  var storeName = 'Store_' + window.btoa(identifiers.tutorial_id + identifiers.tutorial_version);

  var closeStore = function closeStore(store) {
    store._dbp.then(function (db) {
      db.close();
    });
  };

  var storeCreated;

  try {
    var testStore = new window.idbKeyval.Store(dbName, storeName);
    closeStore(testStore);
    storeCreated = true;
  } catch (error) {
    storeCreated = false;
  }

  if (storeCreated === false) {
    success({});
    return;
  }

  Shiny.addCustomMessageHandler('tutorial.store_object', function (message) {
    var idbStoreSet = new window.idbKeyval.Store(dbName, storeName);
    window.idbKeyval.set(message.id, message.data, idbStoreSet)["catch"](function (err) {
      console.error(err);
    })["finally"](function () {
      closeStore(idbStoreSet);
    });
  });

  thiz.$removeState = function (completed) {
    var idbStoreClear = new window.idbKeyval.Store(dbName, storeName);
    window.idbKeyval.clear(idbStoreClear).then(completed)["catch"](function (err) {
      console.error(err);
      completed();
    })["finally"](function () {
      closeStore(idbStoreClear);
    });
  };

  var idbStoreGet = new window.idbKeyval.Store(dbName, storeName);
  window.idbKeyval.keys(idbStoreGet).then(function (keys) {
    var getPromises = keys.map(function (key) {
      return window.idbKeyval.get(key, idbStoreGet);
    });
    return Promise.all(getPromises).then(function (vals) {
      var ret = {};
      var i;

      for (i = 0; i < keys.length; i++) {
        ret[keys[i]] = vals[i];
      }

      return ret;
    });
  }).then(function (objs) {
    success(objs);
  })["catch"](function (err) {
    console.error(err);
    success({});
  })["finally"](function () {
    closeStore(idbStoreGet);
  });
};

Tutorial.prototype.$restoreState = function (objects) {
  var thiz = this;
  thiz.$logTiming('restoring-state');
  this.$serverRequest('restore_state', objects, function (data) {
    thiz.$logTiming('state-received');
    thiz.$initializeClientState(data.client_state);
    thiz.$fireInit();
    thiz.$initializeProgress(data.progress_events);
    thiz.$restoreSubmissions(data.submissions);
    thiz.$initializeVideoPlayers(data.video_progress);
  });
};

Tutorial.prototype.$restoreSubmissions = function (submissions) {
  var thiz = this;

  for (var i = 0; i < submissions.length; i++) {
    var submission = submissions[i];
    var type = submission.type;
    var id = submission.id;

    if (type === 'exercise_submission') {
      var label = id;
      var code = submission.data.code;
      var checked = submission.data.checked;
      thiz.$logTiming('restoring-exercise-' + label);
      var editorContainer = thiz.$exerciseEditor(label);

      if (editorContainer.length > 0) {
        (function () {
          var editor = ace.edit(editorContainer.attr('id'));
          editor.setValue(code, -1);

          if (window.shinytest) {
            setTimeout(function () {
              editor.blur();
            }, 0);
          }

          thiz.$exerciseForLabel(label).data('restoring', true);
          thiz.$showExerciseProgress(label, 'run', true);
          editorContainer.trigger('restore', {
            should_check: checked
          });
        })();
      }
    }
  }
};

Tutorial.prototype.$removeState = function (completed) {
  completed();
};

Tutorial.prototype.$initializeClientState = function (clientState) {
  var thiz = this;
  var clientStateLast = {
    scroll_position: 0,
    hash: ''
  };
  var maybePersistClientState = this.$debounce(function () {
    var clientStateCurrent = {
      scroll_position: $(window).scrollTop(),
      hash: window.location.hash
    };

    if (clientStateCurrent.scroll_position !== clientStateLast.scroll_position || clientStateCurrent.hash !== clientStateLast.hash) {
      thiz.$serverRequest('set_client_state', clientStateCurrent, null);
      clientStateLast = clientStateCurrent;
    }
  }, 1000);
  $(window).scroll(maybePersistClientState);
  window.addEventListener('popstate', maybePersistClientState);

  if (!window.location.hash && clientState.hash) {
    window.location.hash = clientState.hash;
  }
};

Shiny.addCustomMessageHandler('tutorial_isServerAvailable', function (message) {
  TUTORIAL_IS_SERVER_AVAILABLE = true;
});

Tutorial.prototype.$initializeServer = function () {
  var thiz = this;
  thiz.$logTiming('wait-server-available');

  function initializeServer() {
    function retry(delay) {
      setTimeout(function () {
        initializeServer();
      }, delay);
    }

    if (TUTORIAL_IS_SERVER_AVAILABLE) {
      thiz.$logTiming('server-available');
      thiz.$serverRequest('initialize', {
        location: window.location
      }, function (response) {
        thiz.$logTiming('server-initialized');
        thiz.$initializeStorage(response.identifiers, function (objects) {
          thiz.$logTiming('storage-initialized');
          thiz.$restoreState(objects);
        });
      });
    } else {
      retry(250);
    }
  }

  initializeServer();
};

window.Tutorial = Tutorial;